<template>
  <div class="credit">
    <el-container>
      <el-main>
        <el-container>
          <el-header class="flex-box flex-start text-bold page-title">
            信用查询
          </el-header>
          <el-main>
            <div class="search-box">
              <el-form
                inline
                ref="searchForm"
                label-width="120"
                :model="formInline"
                :rules="searchRules"
              >
                <el-form-item prop="cardNo">
                  <el-input
                    clearable
                    style="width: 320px"
                    v-model="formInline.cardNo"
                    placeholder="请输入身份证号"
                    @clear="handleReset"
                  >
                  <el-button slot="append" size="medium" type="primary" @click="handleSearch">
                    查询
                  </el-button>
                  </el-input>
                </el-form-item>
              </el-form>
            </div>

            <template v-if="formData">
              <el-descriptions title="信用信息" :column="4" class="mt-md">
                <el-descriptions-item label="姓名">
                  {{ formData.name }}
                </el-descriptions-item>
                <el-descriptions-item label="身份证号" :span="3">
                  {{ formData.cardNo }}
                </el-descriptions-item>
                <el-descriptions-item label="借入总金额">
                  {{ formData.borrowAmount || 0 }}
                </el-descriptions-item>
                <el-descriptions-item label="借款笔数">
                  {{ formData.allBorrowCount || 0 }}
                </el-descriptions-item>
                <el-descriptions-item label="借款人数">
                  {{ formData.allBorrowMan || 0 }}
                </el-descriptions-item>
                <el-descriptions-item label="超七日未还金额">
                  {{ formData.outDated7Amount || 0 }}
                </el-descriptions-item>
                <el-descriptions-item label="超七日未还笔数">
                  {{ formData.allOutDated7Count || 0 }}
                </el-descriptions-item>
                <el-descriptions-item label="逾期未还金额">
                  {{ formData.outDatedAmount || 0 }}
                </el-descriptions-item>
                <el-descriptions-item label="逾期未还笔数">
                  {{ formData.allOutDatedCount || 0 }}
                </el-descriptions-item>
                <el-descriptions-item label="未收回笔数">
                  {{ formData.allUnrepayedCount || 0 }}
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </el-main>
        </el-container>
        <template v-if="formData">
          <el-container>
            <el-header
              class="flex-box flex-start"
              style="height: 42px; font-weight: 600"
              >借入信息</el-header
            >
            <el-main>
              <el-table height="430px" :data="listData">
                <el-table-column label="出借人" prop="name">
                  <template slot-scope="scope">
                    {{ (scope.row.lender || {}).name }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="金额(元)"
                  prop="borrowAmount"
                ></el-table-column>

                <el-table-column prop="borrowTime" label="借款时间">
                  <template slot-scope="scope">
                    {{ formatDate(scope.row.borrowTime) }}
                  </template>
                </el-table-column>

                <el-table-column prop="refundTime" label="还款日期">
                  <template slot-scope="scope">
                    {{ formatDate(scope.row.refundTime) }}
                  </template>
                </el-table-column>

                <el-table-column label="状态" prop="remark">
                  <template slot-scope="scope">
                    <span
                      :class="{
                        'color-error':
                          (scope.row.paramMap || {}).finalStatus === '1',
                      }"
                    >
                      {{ (scope.row.paramMap || {}).remark }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </el-main>
          </el-container>
        </template>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { creditQuery, listLendInfos } from "@/api/credit";
import { formatDate } from "@/common/utils";

export default {
  data() {
    return {
      formInline: {
        cardNo: "",
      },
      formData: null,
      searchRules: {
        cardNo: [
          { required: true, trigger: "blur", message: "请输入身份证号" },
        ],
      },
      listData: [],
    };
  },
  methods: {
    formatDate(date) {
      return formatDate(date, "y-m-d");
    },
    handleSearch() {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          this.listData = [];
          this.formData = null;
          creditQuery(this.formInline.cardNo).then((res) => {
            this.formData = res.data || {};
            listLendInfos(this.formData.cardNo).then((rst) => {
              this.listData = rst.data.chujies;
            });
          });
        }
      });
    },
    handleReset() {
      this.formInline = {
        cardNo: "",
      };
      this.$refs.searchForm.clearValidate();
    },
  },
};
</script>

<style lang="less"></style>
