import request from "@/common/request";

export function creditQuery(cardNo) {
  return request({
    method: "get",
    params: {
      _gp: "pc.credit",
      _mt: "query",
      cardNo,
    },
  });
}

export function listLendInfos(cardNo) {
  return request({
    method: "get",
    params: {
      _gp: "pc.credit",
      _mt: "chujieInfos",
      cardNo,
    },
  });
}
